.navbar {
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #CCCCCC;
}

.navbar span {
    margin-left: auto;
    padding: 20px;
}