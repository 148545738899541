.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 20px;
}

.group {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #4a90e2;
    border-radius: 6px;
    max-width: 700px;
    width: 90%;
    position: relative;
}

.field {
    position: relative;
    display: flex;
    flex-direction: column;

    margin: 15px;
    width: calc(100% - 40px);

    padding: 10px 20px;
    border: 1px solid #4a90e2;
    border-radius: 6px;
}

.fieldTypeContainer {
    position: absolute;
    top: -10px;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    width: 100%;
}

.fieldType {
    color: #4a90e2;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0 10px;
    height: 20px;
    background-color: #FFFFFF;
}