.signatureCanvasContainer {
    position: relative;
    height: 210px;
}
.signatureCanvasFieldset {
    border: 1px solid #CCCCCC;
    border-radius: 4px !important;
    position: absolute;
    width: 100%;
    top: -13px;
    bottom: 30px;
}
.signatureCanvasLabelSpan {
    color: rgba(0,0,0,0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 2.4375em;
    letter-spacing: 0.00938em;
    padding: 0 5px;
    white-space: nowrap;
}
.signatureCanvas {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 40px);
}

.signatureCanvasLine {
    position: absolute;
    height: 1px;
    background-color: #000000;
    top: 60%;
    left: 15px;
    right: 15px;
}
