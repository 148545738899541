.node {
    border: 1px solid #0984e3;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    padding: 20px;
    width: 200px;
    text-align: center;
    font-weight: bold;
    color: #0984e3;
    cursor: pointer;
    line-height: 1.2;
    font-size: 0.75rem;
    transition: box-shadow 150ms;
}

.node:hover {
    background: radial-gradient(circle at 18.7% 37.8%, rgba(245, 245, 255) 0%, rgb(250, 250, 255) 90%);
}

.node_selected {
    background: radial-gradient(circle at 18.7% 37.8%, rgba(245, 245, 255) 0%, rgb(250, 250, 255) 90%);
    box-shadow: rgba(33, 123, 201, 0.5) 0 0 10px;
}

.content {
    /*pointer-events: none;*/
}

.handle {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    border: 1px solid #0984e3;
    background-color: #FFFFFF !important;
}

.handleTop {
    top: -6px;
}

.handleBottom {
    bottom: -6px;
}

.handleName {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.5rem;
    font-weight: normal;
    color: #333333;
    padding: 0 4px;
    white-space: nowrap;
    max-width: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.placeholder {
    width: 160px;
    background: #fff;
    border: 1px dashed #bbb;
    color: #bbb;
    box-shadow: none;
}

.avatar {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 4px;
    left: 4px;
    border: 1px solid #0984e3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar svg {
    width: 10px;
    fill: #0984e3;
}

.avatar img {
    filter: none !important;
}