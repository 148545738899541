.editableDivContainer {
    min-height: 100px;
    display: flex;
}

.outline {
    border: 1px solid #CCCCCC;
    border-radius: 4px !important;
}
.outlineFocused {
    border: 1px solid #4a90e2;
}

.editableDiv {
    outline: none;
    padding: 8px;
    width: 100%;
    flex-grow: 1;
    line-height: 30px;
    word-break: break-all;
}

.chip {
    user-select: none;
    border: 1px solid #cccccc;
    margin: 0 2px;
    padding: 3px 10px;
    border-radius: 3px;
    background-color: #eeeeee;
}