.edgeButton {
    cursor: pointer;
    pointer-events: all;
    stroke: #0984e3;
    fill: white;
}

.edgeButton:hover {
    fill: #f5f5f5;
}

.edgeButtonText {
    pointer-events: none;
    user-select: none;
    fill: #0984e3;
}

.edgePath {
    fill: none;
    stroke: #0984e3;
    stroke-width: 1.5;
}
.edgePathSelected {
    stroke: #0984e3;
    filter: drop-shadow( 0px 0px 3px rgba(33, 123, 201, 0.8));

}

.placeholderPath {
    stroke-width: 1;
    stroke-dasharray: 3 3;
    stroke: #bbb;
    fill: none;
}
