.item {
    cursor: pointer;
    transition: background-color 150ms;

}
.item:hover {
    background-color: #EEEEEE;
}

.item:hover .itemAddButton {
    opacity: 1;
    pointer-events: auto;
}
.itemAddButton {
    pointer-events: none;
    opacity: 0;
    transition: opacity 150ms;
}

.scriptArea {
    width: calc(100% - 20px);
    outline: none;
    border: none;
    background-color: #F6F6F6;
    line-height: 1.3rem;
    font-size: 1rem;
    margin: 10px;
    padding: 10px;
    font-family: monospace;
}

.customKeyword {
    color: black;
    background-color: rgba(109, 150, 255, 0.23);
    padding: 1px 5px;
}